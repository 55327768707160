import React, { useEffect} from "react";
import { useStyles } from "./Loading.styled";
import axios from 'axios'
import short from 'short-uuid'


export default function LoadingAirtale({step, setStep, subStep, setSubStep, emailInput, img1, img2, img3, img4}){
    const classes = useStyles();
    const imagesData=[img1, img2, img3, img4]


    useEffect(async () => {

        const data={
          image1: img1,
          image2: img2,
          image3: img3,
          image4: img4,
          email: emailInput.includes('/')?emailInput.replace('/',"."):emailInput
        }
        await axios.post(`${process.env.REACT_APP_BACKEND}api/ai/imageCollect/`, data)
         .then((res)=>{
         let axiosConfig = { headers: { Authorization: "Bearer " + process.env.REACT_APP_APIKEY, 'Content-Type': 'application/json' } }
         const data = {
             "records": [
               {
                 "fields": {
                 "UUID": short.generate(),
                 "Email": emailInput,
                 "Images": "https://www.hairtelligence.com"+res.data.s3_bucket_folder
                 }
               }
             ]
           }
           axios.post(
               "https://api.airtable.com/v0/appumzSpLx1Tn1glE/ParfaitHair",
               data,
               axiosConfig
             ).then(()=>{
                 setStep(step + 1);
                 setSubStep(subStep + 1);
             })
             .catch((error)=>{
                console.log(error)
                document.getElementById("info-text").innerHTML = "Error."
                document.getElementById("info-inner").style.display = ""
                document.getElementById("spinner-inner").innerHTML = "";
             })
           })
      // axios.post(`${process.env.REACT_APP_BACKEND}api/ai/withoutlogin/`, data)
      //.then((res)=>{

      //  console.log(res)
      //  document.getElementById("info-text").innerHTML = "Images Saved!";
      //  document.getElementById("spinner-inner").innerHTML = "";
      //  setStep(step + 1);
      //  setSubStep(subStep + 1);
        
      //  } )
      .catch((error)=>{
        console.log(error)
        document.getElementById("info-text").innerHTML = "Error."
        document.getElementById("info-inner").style.display = ""
        document.getElementById("spinner-inner").innerHTML = "";
      })
    }
      , [])

    return (
        <div style={{width: '100%', marginTop: '30vh'}}>
            <div className={`${classes.spin} ${classes.title}`} style={{width: '10px', marginLeft: '50%'}}>
                <span id="spinner-inner">·   ·</span>
            </div>
            <div className={classes.title} style={{marginTop: '20px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center'}}>
               <span id="info-text">Saving Your Images Now.</span>
            </div>
            <div style={{marginTop: '20px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center'}}>
              <p id="info-inner" style={{display:"None"}}>
                We're really sorry, we are having server issues.
                <br/> 
                Please contact the parfait team at <a href='mailto:support@myparfait.com'>support@myparfait.com</a> and we will assist you with your order sizing as soon as possible!
              </p>
            </div>
        </div>
    )
}

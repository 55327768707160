import React, { useEffect} from "react";
import { useStyles } from "./Loading.styled";
import axios from 'axios'
import short from 'short-uuid'
import Airtable from 'airtable'


export default function Loading({step, setStep, subStep, setSubStep, email, orderRowId, img1, img2, img3, img4, setLaceTint, setLaceTintDescription, setCapSize}){
    const classes = useStyles();
    // const imagesData=[img1, img2, img3, img4]

    var base = new Airtable({apiKey: process.env.REACT_APP_APIKEY,}).base('appx2uRJxqTDK6fcM');


    useEffect(async () => {

        const data={
          image1: img4,
          email: email
        }
       await axios.post(`${process.env.REACT_APP_BACKEND}api/ai/withloginlacetint/`, data)
        .then((res)=>{
          // console.log(res)
          setLaceTint(res.data.lace_tint)
          setLaceTintDescription(res.data.lace_tint_description)
          base('Bundle Orders').update([
            {
              "id": orderRowId,
              "fields": {

                "Lace_Tint_Hex": res.data.lace_tint,
                "Parfait_Lace_Tint": res.data.lace_tint_description,
                "image_link": "https://www.hairtelligence.com/images/"+res.data.s3_bucket_folder
                

              }}],function(err, records) {
                // console.log(records)
                if (err) {
                  console.error(err);
                  return;
                }
         
         
              })
        
        })
        .catch((err)=>{console.log(err)}
        )

        setStep(step+1)
        setSubStep(subStep+1)
    }, [])

    return (
        <div style={{width: '100%', marginTop: '30vh'}}>
            <div className={`${classes.spin} ${classes.title}`} style={{width: '10px', marginLeft: '50%'}}>
                ·   ·
            </div>
            <div className={classes.title} style={{marginTop: '20px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center'}}>
               Processing your images...
            </div>
        </div>
    )
}